<template>
  <div id="app">
    <div class="container">
      <header>
        <img src="/logo.svg">
      </header>
      <home></home>
    </div>
  </div>
</template>

<script>
import Home from "./components/Home";

export default {
  name: 'App',
  components: {
    Home
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

header {
  display: flex;
  width: 100%;
  background-color: rebeccapurple;
}

header img {
  width: 8rem;
  padding: 1rem 1.4rem;
  background-color: white;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
