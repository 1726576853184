<template>
  <div class="view-container">
    <div class="main">
      <div class="videoContainer">
        <div class="video-item">
          <h3>Local Stream</h3>
          <video ref="localVideoElement" autoplay playsinline muted></video>
        </div>
        <div class="video-item">
          <h3>Remote Stream</h3>
          <video ref="remoteVideoElement" autoplay playsinline muted></video>
        </div>
      </div>
    </div>

    <div class="sidebar">
      <!-- request media permission -->
      <h2>1. Step</h2>
      <input type="text" v-model="name" placeholder="name"> <br/><br/>
      <button @click="onRequestUserMedia">Request user media</button>

      <!-- offer call -->
      <h2>2. Step</h2>
      <button @click="onOfferCallClick" :disabled="offerCallButtonDisabled">Offer Call</button>

      <!-- show offered calls -->
      <h2>3. Step</h2>
      <div class="list">
        <div v-for="(callOffer, index) in callOfferList" :key="callOffer.id" @click="selectedCallOfferIndex = index"
             :class="{'selected': index === selectedCallOfferIndex}">
          <span>{{ callOffer.name }} - {{ callOffer.id }}</span>
        </div>
      </div>

      <!-- answer call -->
      <button @click="onAnswerCallClick" :disabled="answerCallButtonDisabled">Answer Call</button>

      <!-- show answered calls -->
      <h2>4. Step</h2>
      <div class="list">
        <div v-for="(callAnswer, index) in callAnswerList" :key="callAnswer.id" @click="selectedCallAnswerIndex = index"
             :class="{'selected': index === selectedCallAnswerIndex}">
          <span>{{ callAnswer.name }} - {{ callAnswer.id }}</span>
        </div>
      </div>

      <!-- accept answer cal -->
      <button @click="onAnswerCallAcceptClick" :disabled="acceptAnswerCallButtonDisabled">Accept answer Call</button>

      <!-- call elements -->
      <h2>5. Call</h2>
      <!-- close connection -->
      <button @click="onCloseConnection">Close connection</button>
    </div>
  </div>
</template>

<script>
import {io} from "socket.io-client";

// const socket = io('ws://localhost:3000');
const socket = io('wss://socket.waldmann-dev.de:443');

export default {
  name: "Home",
  data() {
    return {
      localStream: null,
      peerConnection: null,
      socketId: null,
      callOfferList: [],
      selectedCallOfferIndex: 0,
      callAnswerList: [],
      selectedCallAnswerIndex: 0,
      remoteStream: null,
      iceCandidateList: [],
      waitingForAcceptAnswer: false,
      peerConnectionState: null,
      name: '',
      peerConnectionRemoteSocketId: null,
    }
  },
  mounted() {
    this.initWebSocket()
    this.initWebRTC()
  },
  computed: {
    selectedCallOffer() {
      if (this.selectedCallOfferIndex >= this.callOfferList || this.selectedCallOfferIndex < 0) {
        return null;
      }

      return this.callOfferList[this.selectedCallOfferIndex].offer
    },
    selectedCallAnswer() {
      if (this.selectedCallAnswerIndex >= this.callAnswerList || this.selectedCallAnswerIndex < 0) {
        return null;
      }

      return this.callAnswerList[this.selectedCallAnswerIndex].answer
    },
    offerCallButtonDisabled() {
      return !this.localStream || this.peerConnectionState === 'connected'
    },
    answerCallButtonDisabled() {
      return this.callOfferList.length <= 0 || !this.localStream || this.peerConnectionState === 'connected'
    },
    acceptAnswerCallButtonDisabled() {
      return this.callAnswerList.length <= 0 || !this.localStream || this.peerConnectionState === 'connected'
    },
  },
  methods: {
    initWebSocket() {
      socket.on('onGetId', id => {
        console.log(id, 'id')
        this.socketId = id;
      })

      socket.on('onCallOffer', callOfferPackage => {
        console.log('onCallOffer', callOfferPackage)
        if (this.socketId !== callOfferPackage.id) {
          this.callOfferList.push(callOfferPackage)
          this.peerConnectionRemoteSocketId = callOfferPackage.id
        }
      })

      socket.on('onCallAnswer', callAnswerPackage => {
        console.log('onCallAnswer', callAnswerPackage)
        if (this.socketId !== callAnswerPackage.id) {
          this.callAnswerList.push(callAnswerPackage)
          this.peerConnectionRemoteSocketId = callAnswerPackage.id
        }
      })

      socket.on('onIceCandidate', async iceCandidatePackage => {
        console.log('onIceCandidate', iceCandidatePackage)
        if (this.peerConnectionRemoteSocketId === iceCandidatePackage.id) {
          try {
            await this.peerConnection.addIceCandidate(new RTCIceCandidate(iceCandidatePackage.iceCandidate));

            if (this.waitingForAcceptAnswer) {
              this.iceCandidateList.forEach(iceCandidate => {
                socket.emit('onIceCandidate', {id: this.socketId, name: this.name, iceCandidate});
              })
              this.waitingForAcceptAnswer = false;
            }
          } catch (e) {
            console.error('Error adding received ice candidate', e);
          }

        }
      })

      socket.on('onCloseCall', closeCallPackage => {
        if (this.peerConnectionRemoteSocketId === closeCallPackage.id) {
          console.log('onCloseCall', closeCallPackage)

          this.peerConnection.close()
          this.peerConnectionState = null;
          this.peerConnectionRemoteSocketId = null;
          this.$refs.remoteVideoElement.srcObject = null;
          this.initWebRTC()

          this.callOfferList = [];
          this.callAnswerList = [];
        }
      })
    },
    initWebRTC() {
      const defaultConfiguration = {
        iceServers: [
          {
            "urls": "stun:stun.l.google.com:19302",
          }
        ]
      };

      this.peerConnection = new RTCPeerConnection(defaultConfiguration);

      this.peerConnection.addEventListener('icecandidate', event => {
        if (event.candidate) {
          console.log('icecandidate', event.candidate)

          this.iceCandidateList.push(event.candidate)
        }
      });

      this.peerConnection.addEventListener('connectionstatechange', () => {
        console.log('STATE', this.peerConnection.connectionState)
        if (this.peerConnection.connectionState === 'connected') {
          this.peerConnectionState = this.peerConnection.connectionState;
        }
        else if (this.peerConnection.connectionState === 'disconnected') {
          this.peerConnectionState = this.peerConnection.connectionState;
        }
      });

      this.peerConnection.ontrack = event => {
        event.streams[0].getTracks().forEach((track) => {
          this.remoteStream.addTrack(track);
        });
      };
    },
    initStreamElements() {
      this.remoteStream = new MediaStream();
      this.$refs.remoteVideoElement.srcObject = this.remoteStream;
    },
    async onRequestUserMedia() {
      this.localStream = await navigator.mediaDevices.getUserMedia({video: true, audio: true});
      this.$refs.localVideoElement.srcObject = this.localStream;

      this.localStream.getTracks().forEach((track) => {
        this.peerConnection.addTrack(track, this.localStream);
      });
    },
    async onOfferCallClick() {
      this.initStreamElements();

      const offerDescription = await this.peerConnection.createOffer();
      await this.peerConnection.setLocalDescription(offerDescription);

      socket.emit('onCallOffer', {id: this.socketId, name: this.name, offer: offerDescription})
    },
    async onAnswerCallClick() {
      this.initStreamElements();

      const remoteDescription = new RTCSessionDescription(this.selectedCallOffer);
      await this.peerConnection.setRemoteDescription(remoteDescription);

      const answerDescription = await this.peerConnection.createAnswer();
      await this.peerConnection.setLocalDescription(answerDescription);

      socket.emit('onCallAnswer', {id: this.socketId, name: this.name, answer: answerDescription})
      this.waitingForAcceptAnswer = true;
    },
    async onAnswerCallAcceptClick() {
      const remoteDescription = new RTCSessionDescription(this.selectedCallAnswer);
      await this.peerConnection.setRemoteDescription(remoteDescription);

      this.iceCandidateList.forEach(iceCandidate => {
        socket.emit('onIceCandidate', {id: this.socketId, name: this.name, iceCandidate});
      })
    },
    onCloseConnection() {
      socket.emit('onCloseCall', {id: this.socketId, name: this.name})
    }
  }
}
</script>

<style scoped>
.list {
  width: 50%;
  margin: 0 auto;
  cursor: pointer;
}
.selected {
  background-color: #e9e9e9;
}


.videoContainer {
  display: flex;
  justify-content: center;
  height: 100%;
}

video {
  width: 100%;
}

.view-container {
  display: flex;
  height: 100%;
}

.video-item {
  display: flex;
  position: relative;
  width: 50%;
  background-color: #282828;
  border: 1px solid #444444;
  height: 100%;
}

.video-item h3 {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.4rem 0.8rem;
  color: white;
}

.main {
  width: 70%;
}

.sidebar {
  width: 30%;
  background-color: #01011e;
  color: white;
  height: 100%;
  text-align: center;
  border: 1px solid #444444;
}

</style>
